import "./index.css";
import Railway from "./Railway/Railway";
import Tags from "./Tags/Tags";
import NavBar from "./NavBar/NavBar";
import RailwayNumbered from "./Railway/RailwayNumbered";
import background from "./Images/back.jpg";
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: HardBread (https://sketchfab.com/Alexey..Tverdohleb)
license: CC-BY-NC-4.0 (http://creativecommons.org/licenses/by-nc/4.0/)
source: https://sketchfab.com/3d-models/railway-ca1cc6ea0ae84816882cf790cb980aec
title: Railway
*/


// https://github.com/CesiumGS/gltf-pipeline

/*
Ref: https://www.youtube.com/watch?v=xy_tbV4pC54

https://github.com/CesiumGS/gltf-pipeline: 

1. Converting a glTF to glb

  gltf-pipeline -i scene.gltf -o scene.glb

2. compress up to level 10:
 
 gltf-pipeline -i scene.glb -o scene_draco.glb --draco.compressionLevel=10

3. Genarate rxjs elements 

  npx gltfjsx fileName.glb
*/

// Change color : https://www.youtube.com/watch?v=2jwqotdQmdQ

function App() {


    return (
        <>
            <div className="App">
                <NavBar/>
                <div style={{ backgroundImage: `url(${background})` ,width:'100vw',height:'100vh'}}>
                    <RailwayNumbered/>
                    <div>
                        <Tags/>
                    </div>
                </div>
            </div>
        </>

    );
}

export default App;
