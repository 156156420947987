/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

// import React, { useRef } from 'react'
// import { useGLTF } from '@react-three/drei'
// import {proxy} from "valtio";

import React, {useEffect, useRef, useState} from 'react'
import {Html, OrbitControls, useGLTF} from '@react-three/drei'
import {TextureLoader} from 'three/src/loaders/TextureLoader';
import {useFrame, useLoader} from "@react-three/fiber";
import {proxy, useSnapshot} from 'valtio';
import axios from "axios";

const state = proxy({
    current: null,
    items: {
        redGeo: "#EF1E38",
        greenGeo: "#3f3",

    },

})

export default function ModelSmallNumbered({props}) {
    const [flag, setFlag] = useState(true);
    const [data, setData] = useState([]);
    const getResults = async () => {
        setTimeout(() => setFlag(!flag), 1000)
        const {data: response} = await axios.get('https://ugp-foe-api.herokuapp.com/api/uhftags');
        setData(response.data);
        console.log(response.data)

    }
    useEffect(() => {
        getResults();
    }, [flag])

    const changeColor = (geoId) => {
        const filtered = data.filter((uhf) => uhf.id === geoId)
        const mapped = filtered.map((statuss)=> statuss.status)
        return mapped
    }
    const myGroup = useRef()
    const { nodes, materials } = useGLTF('/RailwaySmallNumbered/railwaynumbered.glb')
    const snap = useSnapshot(state)

    //Geo Location rotating feature for all six geo tags
    const group1 = useRef()
    useFrame((state, delta) => (group1.current.rotation.y += 0.05))

    const group2 = useRef()
    useFrame((state, delta) => (group2.current.rotation.y += 0.05))

    const group3 = useRef()
    useFrame((state, delta) => (group3.current.rotation.y += 0.05))

    const group4 = useRef()
    useFrame((state, delta) => (group4.current.rotation.y += 0.05))

    const group5 = useRef()
    useFrame((state, delta) => (group5.current.rotation.y += 0.05))

    const group6 = useRef()
    useFrame((state, delta) => (group6.current.rotation.y += 0.05))

    const [color, setColor] = useState(snap.current)

    return (
        <>
        <group ref={myGroup} {...props} dispose={null}>
            <group rotation={[-Math.PI / 2, 0, 0]}>
                <group rotation={[Math.PI / 2, 0, 0]}>
                    <group position={[178.88, -12.81, 0]}>
                        <mesh geometry={nodes.m_Railway_mt_Railway_0.geometry} material={materials.mt_Railway} />
                    </group>
                </group>
            </group>

            //1st geo location
            <group position={[-1.97, 36.45, 172.84]} rotation={[-Math.PI / 2, 0, 0]} scale={25}>
                <group ref={group1} rotation={[Math.PI / 2, 0, 0]}>
                    <mesh geometry={nodes.Object_4.geometry} material={materials.material}  material-color={changeColor(1).toString() ==='TIGHT' ?  snap.items.greenGeo : snap.items.redGeo} />
                    <mesh geometry={nodes.Object_5.geometry} material={materials.white} />
                </group>
            </group>

            //1st Number-1
            <group  position={[-2.37, 65.69, 172.82]} rotation={[-Math.PI / 2, 0, 0]} scale={[20, 10, 20]}>
                <group rotation={[Math.PI / 2, 0, 0]}>
                    <group  rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_4001.geometry} material={materials.Shine_blue_th} />
                    </group>
                    <group  rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_6.geometry} material={materials.Metal_gray} />
                    </group>
                    <group  rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_8.geometry} material={materials.Metal_gray} />
                    </group>
                </group>
            </group>

            //2nd geo Location
            <group position={[176.85, 36.22, 173.06]} rotation={[-Math.PI / 2, 0, 0]} scale={25}>
                <group ref={group2} rotation={[Math.PI / 2, 0, 0]}>
                    <mesh geometry={nodes.Object_4002.geometry} material={materials['material.001']}   material-color={changeColor(2).toString() ==='TIGHT' ?  snap.items.greenGeo : snap.items.redGeo}/>
                    <mesh geometry={nodes.Object_5001.geometry} material={materials['white.001']} />
                </group>
            </group>

            //2nd Number-2
            <group position={[176.38, 65.01, 173.03]} rotation={[-Math.PI / 2, 0, 0]} scale={[20, 10, 20]}>
                <group  rotation={[Math.PI / 2, 0, 0]}>
                    <group  rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_4003.geometry} material={materials['Shine_blue_th.001']} />
                    </group>
                    <group rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_6001.geometry} material={materials['Metal_gray.001']} />
                    </group>
                    <group rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_8001.geometry} material={materials['Metal_gray.001']} />
                    </group>
                </group>
            </group>

            //3rd geo Location
            <group position={[355.92, 36.47, 172.98]} rotation={[-Math.PI / 2, 0, 0]} scale={25}>
                <group ref={group3} rotation={[Math.PI / 2, 0, 0]}>
                    <mesh geometry={nodes.Object_4004.geometry} material={materials['material.002']}  material-color={changeColor(3).toString() ==='TIGHT' ?  snap.items.greenGeo : snap.items.redGeo}/>
                    <mesh geometry={nodes.Object_5002.geometry} material={materials['white.002']} />
                </group>
            </group>

            //3rd Number-3
            <group position={[356.2, 65.36, 172.95]} rotation={[-Math.PI / 2, 0, 0]} scale={[20, 10, 20]}>
                <group rotation={[Math.PI / 2, 0, 0]}>
                    <group   rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_4005.geometry} material={materials['Shine_blue_th.002']} />
                    </group>
                    <group rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_6002.geometry} material={materials['Metal_gray.002']} />
                    </group>
                    <group rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_8002.geometry} material={materials['Metal_gray.002']} />
                    </group>
                </group>
            </group>

            //4th geo Location
            <group position={[356.39, 36.5, -172.19]} rotation={[-Math.PI / 2, 0, 0]} scale={25}>
                <group ref={group4} rotation={[Math.PI / 2, 0, 0]}>
                    <mesh geometry={nodes.Object_4006.geometry} material={materials['material.003']}  material-color={changeColor(4).toString() ==='TIGHT' ?  snap.items.greenGeo : snap.items.redGeo} />
                    <mesh geometry={nodes.Object_5003.geometry} material={materials['white.003']} />
                </group>
            </group>

            //4th Number-4
            <group position={[356.75, 64.05, -172.2]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[20, 10, 20]}>
                <group rotation={[Math.PI / 2, 0, 0]}>
                    <group  rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_4007.geometry} material={materials['Shine_blue_th.003']} />
                    </group>
                    <group rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_6003.geometry} material={materials['Metal_gray.003']} />
                    </group>
                    <group rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_8003.geometry} material={materials['Metal_gray.003']} />
                    </group>
                </group>
            </group>

            //5th geo Location
            <group position={[177.13, 36.33, -172.24]} rotation={[-Math.PI / 2, 0, 0]} scale={25}>
                <group ref={group5} rotation={[Math.PI / 2, 0, 0]}>
                    <mesh geometry={nodes.Object_4008.geometry} material={materials['material.004']}  material-color={changeColor(5).toString() ==='TIGHT' ?  snap.items.greenGeo : snap.items.redGeo} />
                    <mesh geometry={nodes.Object_5004.geometry} material={materials['white.004']} />
                </group>
            </group>

            //5th number-5
            <group position={[177.09, 65.04, -172.25]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[20, 10, 20]}>
                <group rotation={[Math.PI / 2, 0, 0]}>
                    <group  rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_4009.geometry} material={materials['Shine_blue_th.004']} />
                    </group>
                    <group rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_6004.geometry} material={materials['Metal_gray.004']} />
                    </group>
                    <group rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_8004.geometry} material={materials['Metal_gray.004']} />
                    </group>
                </group>
            </group>
            //6th geo location
            <group position={[-1.7, 36.46, -172.29]} rotation={[-Math.PI / 2, 0, 0]} scale={25}>
                <group ref={group6} rotation={[Math.PI / 2, 0, 0]}>
                    <mesh geometry={nodes.Object_4010.geometry} material={materials['material.005']}  material-color={changeColor(6).toString() ==='TIGHT' ?  snap.items.greenGeo : snap.items.redGeo}/>
                    <mesh geometry={nodes.Object_5005.geometry} material={materials['white.005']} />
                </group>
            </group>

            //6th number-6
            <group position={[-1.71, 65.37, -172.27]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[20, 10, 20]}>
                <group rotation={[Math.PI / 2, 0, 0]}>
                    <group rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_4011.geometry} material={materials['Shine_blue_th.005']} />
                    </group>
                    <group rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_6005.geometry} material={materials['Metal_gray.005']} />
                    </group>
                    <group rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.Object_8005.geometry} material={materials['Metal_gray.005']} />
                    </group>
                </group>
            </group>
        </group>
        </>
    )
}


